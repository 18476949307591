.footer-style {
    background-color: var(--main-color);
    color: var(--primary-color);
    font-size: 1.1em;
    min-height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;
    margin-top: 10vh;
}
