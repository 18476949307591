body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    scroll-behavior: smooth;
}

:root {
    --main-color: #333333;
    --alternate-color: #EEEEEE;
    --primary-color: #FFFFFF;
    --secondary-color: #000000;
    --text-shadow-color: #BBBBBB;
}