.App {
    text-align: center;
    background-color: var(--alternate-color);
    font-size: 1em;
    color: black;
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.app-nav {
    padding-top: 70px;
    z-index: 2;
    padding-bottom: 4vh;
}

.app-container {
    position: relative;
    z-index: 2;
    width: 90%;
}


h1 {
    font-size: 2.5em;
    font-weight: bold;
    color: var(--secondary-color);
    text-shadow: 1px 1px var(--text-shadow-color);
    margin: 0 auto 1em;
    border-bottom: 1px solid black;
    width: 50%;
}
