.about-link {
    color: black;
}

.about-header {
    font-size: 1.5em;
}

.about-card {
    border-color: black;
    background-color: var(--primary-color);
    border-width: 0.15em;
    max-width: 75%;
    margin: 0 auto;
}