.home-horizontal {
    height: 100vh;
    display: flex;
    align-items: center;
}

.home-vertical {
    flex-direction: column;
}

.home-name {
    color: darkred;
    font-size: 3em;
    font-weight: bold;
    text-shadow: 1px 1px var(--text-shadow-color);
}

.home-headshot {
    max-width: 40%;
    border-radius: 50%;
    border: var(--main-color) 3px solid;
}

@media only screen and (max-width: 1925px) {
    .home-headshot {
        max-width: 30%;
    }
}


@media only screen and (max-width: 1025px) {
    .home-headshot {
        max-width: 35%;
    }
}

@media only screen and (max-width: 775px) {
    .home-headshot {
        max-width: 40%;
    }
}

@media only screen and (max-width: 450px) {
    .home-headshot {
        max-width: 75%;
    }
}

.home-typewriter {
    color: var(--secondary-color);
    font-size: 2em;
    font-weight: bold;
    text-shadow: 1px 1px var(--text-shadow-color);
    margin-bottom: 2vh;
}

.home-down-button {
    color: var(--main-color);
    transition: transform 0.3s;
    animation: MoveUpDown 1.75s linear infinite;
}

/*.home-down-button:hover {*/
/*    animation-play-state: paused;*/
/*}*/

@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(50%);
    }
}





